import modStyl from './CallPhoneNumber.module.scss'
import React, { useEffect, useRef, useState } from 'react';
import { Button, message as Message } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import { formatSeconds } from './utils';
import { callPhoneNumber, doNotReady, doReady, hangUp, login, logout, start, stop } from './cc/aiccyun';
import { saveVisitRecord } from '../../../../services/dct/visit';
import storageX, { Engine } from '../../../../util/storageX';

type ButtonProps = {
  onClick: ()=> void
}
const CallButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(180deg)'}}/>
      <span>拨打电话</span>
    </Button>
  )
}

const HangUpButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} danger={true} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(224deg)'}}/>
      <span>挂断电话</span>
    </Button>
  )
}

let timer: any;

enum AgentStatusEnum {
  // 初始状态
  None,
  // 已登录
  Login,
  // 准备就绪(或挂断通话)
  Ready,
  // 振铃中
  Alerting,
  // 通话中
  Connected,
  // 座席被其他人点用
  Otherwork,
}


type Props = {
  phoneNumber: string;
  visitTaskId: number;
  // 用于标记需要刷新列表
  refresh: (state: boolean)=> void;
}
const CallPhoneNumber: React.FC<Props> = ({phoneNumber, visitTaskId, refresh})=> {
  // 
  const [status, setStatus] = useState(AgentStatusEnum.None)
  const [seconds, setSeconds] = useState(0)

  const hangupSessionId = useRef<string[]>([])
  
  // 主动挂断电话
  const handleHangup = (sessionId?: string)=> {
    clearInterval(timer)
    setSeconds(0)
    setStatus(AgentStatusEnum.Ready)
    // 挂断
    hangUp()
    // 挂断自动置闲
    doReady()

    // 推送
    if(sessionId) {
      saveVisitRecord(visitTaskId, sessionId).then(({code, message})=> {
        if(0 === code) {
          console.log('dddd')
          refresh(true)
        } else {
          Message.error(message)
        }
      }).catch(()=>{})
    }
  }

  // 拨打电话
  const handleCall = ()=> {
    // 清空
    hangupSessionId.current.splice(0)
    // 拨号
    const cNumber = storageX(Engine.Session).get('phoneNumber', phoneNumber) as string

    callPhoneNumber(cNumber)
    // 拨号后自动置忙
    doNotReady()
  }

  // 退出
  useEffect(()=> {
    const callback = ({type, sessionId}: {type: string, sessionId: string})=> {
      // 登录成功
      if(type === 'AgentStatus_Login') {
        doReady()
        setStatus(AgentStatusEnum.Ready)
      }
      // 座席准备就绪可以外呼了
      else if(type === 'AgentStatus_Ready') {
        setStatus(AgentStatusEnum.Ready)
      }
      // 对方接听电话
      else if(type === 'AgentStatus_Alerting') {
        setStatus(AgentStatusEnum.Alerting)
        timer = setInterval(()=> setSeconds((preSeconds)=> preSeconds+1), 1000)
      }
      else if (type === "AgentStatus_Connected_Normal") {
        setStatus(AgentStatusEnum.Connected)
      }
      // 座席退出
      else if(type === 'AgentStatus_Logout') {
        //
      }
      // 
      // else if(type === 'AgentStatus_Otherwork') {
      //   Modal.warn({
      //     title: '座席被其他人点用',
      //   })
      // }
      // 对方挂断电话
      else if (type === 'AgentStatus_Disconnected') {
        setStatus(AgentStatusEnum.Ready)
        // 因为是自动挂断，会推送多次
        if(hangupSessionId.current.includes(sessionId)) {
          return
        }
        hangupSessionId.current.push(sessionId)
        // 挂断
        handleHangup(sessionId)
      }
    }
    start(callback)
      .then(()=> {
        setTimeout(()=> login, 500)
        console.log('++++++++++++++++++++++++++++++++++++++++++初始化外呼成功')
      })
      .catch((e)=> {
        console.log('初始化外呼失败')
      })
    
    return ()=> {
      logout()
      stop()
    }
  }, [])

  return (
    <div className={modStyl.callPhoneNumber}>
      <div className={modStyl.r}>
        {status === AgentStatusEnum.None && <span>正在连接...</span>}
        {status === AgentStatusEnum.Login && <span>已登录</span>}
        {status === AgentStatusEnum.Ready && <span>&nbsp;</span>}
        {status === AgentStatusEnum.Alerting && <span className={modStyl.ringing}>拨号中...</span>}
        {status === AgentStatusEnum.Connected && <span className={modStyl.seconds}>{formatSeconds(seconds)}</span>}
      </div>
      <div className={modStyl.r}>
        {/* 打电话 */}
        {status === AgentStatusEnum.Ready && <CallButton onClick={handleCall}/>}
        {/* 挂断电话 */}
        {(status === AgentStatusEnum.Alerting  || status === AgentStatusEnum.Connected)&& <HangUpButton onClick={()=> handleHangup()}/>}
      </div>
    </div>
  )
}

export default CallPhoneNumber