import { message as messager } from "antd";
import { getAgentUserInfo } from "../../../../../services/dct/visit";
import storageX, { Engine } from "../../../../../util/storageX"

type Params = {
  httpServerAddress: string;
  agentId: string;
  entId: string;
  password: string;
  sipUser: string;
  agentDN: string;
  TerminalType: string;
  agentDnPwd: string;
  autoRTCAnswer: boolean,
  serverUrl: string;
  wsUrl: string;
  udpUrl: string;
  stunServers: string;
  channel: string;
  skllDesc?: string;
}

type UserInfo = {
  // 坐席分机
  agentDn: string;
  // 坐席工号
  agentId: string;
  // 坐席密码 MD5加密
  agentPwd: string;
}

const cacheKey = 'aiccyun'

const storeX = storageX(Engine.Session)

let agentInstance: any = null;

const fetchAICCYunUserInfo = async (): Promise<UserInfo | undefined>=> {
  const { code, data } = await getAgentUserInfo<UserInfo[]>()
  if(code === 0) {
    if(Array.isArray(data) && data.length === 1) {
      return {
        agentDn: data[0].agentDn,
        agentId: data[0].agentId,
        agentPwd: data[0].agentPwd
      }
    }
  }
}

// 获取当前绑定坐席并缓存到本地
const getUserInfo = (): Promise<UserInfo>=> {
  return new Promise(async(resolve, reject)=> {
    try {
      const userInfo = storeX.json().get(cacheKey) as UserInfo
      if(userInfo) {
        resolve(userInfo)
      } else {
        const data = await fetchAICCYunUserInfo()
        if(data) {
          storeX.set(cacheKey, data)
          resolve(data)
        } else {
          reject('坐席未配置或配置错误')
        }
      }
    } catch (error) {
      reject(error)
    }
  })
}

// 获取青牛配置参数
const fetchAICCYunConfig = async (): Promise<Params>=> {
  return new Promise(async (resolve, reject)=> {
    try {
      // 企业编号
      const entId = "HM"
      // 服务地址
      const httpServerAddress = 'https://www.aisysten.com:808'

      const { agentDn, agentId, agentPwd } = await getUserInfo()

      const params = {
        httpServerAddress: httpServerAddress,
        agentId: agentId,
        entId: entId,
        password: agentPwd,
        sipUser: agentDn,
        agentDN: agentDn,
        TerminalType: '5', // 5需要https
        agentDnPwd: '1234',
        autoRTCAnswer: true,
        serverUrl: 'v4.huayunworld.com',
        wsUrl: 'v4.huayunworld.com:21000',
        udpUrl: 'v4.huayunworld.com:35065',
        stunServers: '81.70.204.246:3478',
        channel: 'external', // 接入渠道：外部接入-external(写死即可);
      }
      window.phoneUserInfo = params
      resolve(params)
    } catch (error) {
      messager.error(error)
    }
  })
}

export const start = async (callback: (event: {type: string, sessionId: string})=> void)=> {
  try {
    agentInstance = new window.AgentBar(window, document)
    agentInstance.start((result: any) => {
      console.log('[START]####################################')
      console.log(JSON.stringify(result))
      console.log('[  END]####################################')
      callback({
        type: result.EventType ?? '',
        sessionId: result.SessionID ? result.SessionID.split(':').shift() : '',
      })
    })
  
    // 获取配置参数
    const params = await fetchAICCYunConfig()
  
    agentInstance.initUCObj(
      params.agentId,
      params.password,
      params.entId,
      params.agentDN,
      params.agentId,
      params.TerminalType,
      params.skllDesc,
      params.httpServerAddress,
      params.agentDnPwd,
      params.serverUrl,
      params.wsUrl,
      params.udpUrl,
      params.stunServers
    )
  } catch (error) {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    console.log(error)
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  }
}

export const login = ()=> {
  agentInstance.loginex(()=> {})
}

/**
 * 打电话
 * @param phoneNumber 
 * @param displayPhoneNumber 
 */
export const callPhoneNumber = (phoneNumber: string, displayPhoneNumber: string|null=null)=> {
  agentInstance.doCallOut(`0${phoneNumber}`, displayPhoneNumber)
}

// 获取坐席状态
export const getAgentState = ()=> {
  agentInstance.GetAgentState()
}

// 坐席置闲
export const doReady = ()=> {
  agentInstance.doReady()
}

// 坐席置忙
export const doNotReady = ()=> {
  agentInstance.doNotReady()
}

// 挂机
export const hangUp = ()=> {
  agentInstance.doDisconnect()
}

// 登出
export const logout = ()=> {
  agentInstance.doLogOut(()=> {
    console.log('?logout')
  })
}

// 服务停止
export const stop = ()=> {
  if(agentInstance) {
    agentInstance.stopObj()
    agentInstance = null
  }
}